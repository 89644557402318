export class LoginUtils {
    static hashCode(str) {
        for (var i = 0, h = 0; i < str.length; i++) {
            h = Math.imul(31, h) + str.charCodeAt(i) | 0;
        }
        return h;
    }
    static base64UrlEncode(str) {
        return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
    }
    static base64UrlDecode(str) {
        str = (str + '===').slice(0, str.length + (str.length % 4));
        return atob(str.replace(/-/g, '+').replace(/_/g, '/'));
    }
    static getCookieValue(cookieName) {
        return cookieName && (('; ' + document.cookie).split('; ' + cookieName + '=').pop() || '').split(';').shift();
    }
    static setCookieValue(cookieName, cookieValue, defaultCookieOptions) {
        if (cookieName) {
            const cookieOptions = Object.assign({}, defaultCookieOptions || {}, {
                expires: new Date(2147483647 * 1000).toUTCString()
            });
            document.cookie = cookieName + '=' + cookieValue + ';' + Object.keys(cookieOptions).map(key => key + '=' + cookieOptions[key]).join(';');
        }
    }
    static goToUrl(url) {
        if (window.location.href == url.toString()) {
            window.location.reload(true);
        }
        else {
            window.location.href = url.toString();
        }
    }
    static getBaseUrl() {
        return new URL('.', window.location.href);
    }
    static getTargetUrl(targetParameterName = 'target') {
        const currentUrl = new URL(window.location.href);
        let targetUrl = this.getBaseUrl();
        // Check for requested targetUrl
        let targetParameterValue = currentUrl.searchParams.get(targetParameterName);
        if (targetParameterValue) {
            try {
                targetUrl = new URL(targetParameterValue);
            }
            catch (e) {
                console.error("Could not parse requested targetUrl", e);
            }
        }
        // Preserve current hash if none defined
        targetUrl.hash || (targetUrl.hash = currentUrl.hash);
        return targetUrl;
    }
    static async userLogin(loginApi, username, password) {
        let response = await fetch(loginApi || 'rest/v2/auth/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password }),
            redirect: 'manual',
            credentials: 'include'
        });
        let status = response.status;
        let body = await response.text();
        // API V2 Response check
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const data = JSON.parse(body);
            if ((data === null || data === void 0 ? void 0 : data.successfull) !== true) {
                status = 403;
            }
        }
        return { status: status, body: body };
    }
    static samlLogin(idp, redirectParameters, baseUrl = this.getBaseUrl(), targetUrl = this.getTargetUrl()) {
        let samlUrl = new URL('saml/login', baseUrl);
        samlUrl.searchParams.set('base', baseUrl.toString());
        samlUrl.searchParams.set('target', targetUrl.toString());
        if (idp) {
            samlUrl.searchParams.set('idp', idp);
        }
        if (redirectParameters) {
            if (redirectParameters instanceof Map && redirectParameters.size > 0) {
                let redirectSearchParameters = new URLSearchParams();
                redirectParameters.forEach((value, key) => {
                    redirectSearchParameters.append(key, value);
                });
                samlUrl.searchParams.set('redirectParameters', redirectSearchParameters.toString());
            }
            else if (typeof redirectParameters === "string") {
                samlUrl.searchParams.set('redirectParameters', redirectParameters);
            }
        }
        this.goToUrl(samlUrl);
    }
    static processTemplateUrl(url, templateVariables) {
        url.pathname = this.processTemplate(decodeURIComponent(url.pathname), templateVariables);
        url.searchParams.forEach((value, key) => {
            url.searchParams.set(key, this.processTemplate(value, templateVariables));
        });
        return url;
    }
    static processTemplate(template, templateVariables) {
        if (templateVariables) {
            templateVariables.forEach((value, key) => {
                template = template.replace('{' + key + '}', value);
            });
        }
        return template;
    }
}
