var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import "polymer/polymer-element.html";
import "iron-flex-layout/iron-flex-layout-classes.html";
import "./page-login.html";
import "../theme.html";
import "../../../widgets/cover-image-default/cover.image-default.ts";
import { customElement, property } from "taktik-polymer-typescript";
import { LoginUtils } from "../../../app.utils";
let PageLoginDefault = class PageLoginDefault extends Polymer.Element {
    constructor() {
        super(...arguments);
        this.username = '';
        this.password = '';
        this.focusedlogin = false;
        this.focusedpw = false;
        this.errorMessage = '';
        this.ozoneInstance = '';
        this.ozoneVirtualHost = '';
        this.language = '';
        this.localized = {};
        this.isShowPassword = false;
        this.displayForm = false;
        this.shouldDisplayOr = false;
        this.displayAuthenticationSchemes = true;
        this.shouldDisplayBackBtn = false;
        this.automaticSelectionCssClass = 'authenticationSchemeAutomaticSelection';
        this.automaticSelectionAuthenticationScheme = null;
        this.automaticSelectionTimer = null;
    }
    register(e) {
        location.href = "#/register";
    }
    requestPassword(e) {
        location.href = "#/password";
    }
    ready() {
        super.ready();
        this.init();
    }
    init() {
        var _a, _b;
        const currentUrl = new URL(window.location.href);
        this.set('errorMessage', currentUrl.searchParams.get('error'));
        this.set('displayForm', (_a = this.config.display) === null || _a === void 0 ? void 0 : _a.displayForm);
        this.set('shouldDisplayOr', ((_b = this.config.display) === null || _b === void 0 ? void 0 : _b.displayForm) && this.countDisplayedAuthenticationSchemes() > 0);
        // Fix conflict between label placeholder value and browser auto-completion
        if (this.displayForm) {
            this.set('focusedpw', true);
            this.set('focusedlogin', true);
        }
        // Lookup selected authenticationScheme (cookie or default, if no initial errorMessage)
        if (!this.errorMessage) {
            const selectedAuthenticationSchemeIdentifier = LoginUtils.getCookieValue(this.config.selectionCookieName);
            this.automaticSelectionAuthenticationScheme = (selectedAuthenticationSchemeIdentifier) ? this.getAuthenticationSchemeByIdentifier(selectedAuthenticationSchemeIdentifier) : null;
            if (!this.automaticSelectionAuthenticationScheme && this.config.authenticationSchemes) {
                for (const authenticationScheme of this.config.authenticationSchemes) {
                    if (authenticationScheme.selected) {
                        this.automaticSelectionAuthenticationScheme = authenticationScheme;
                        break;
                    }
                }
            }
        }
        // Set automaticSelectionTimer if automaticSelectionAuthenticationScheme is defined
        if (this.automaticSelectionAuthenticationScheme) {
            this.automaticSelectionTimer = setTimeout(() => {
                var _a;
                // Select authenticationScheme
                this.selectAuthenticationScheme(this.automaticSelectionAuthenticationScheme);
                // Try to submit form if selected and pre-filled
                if (!((_a = this.config.display) === null || _a === void 0 ? void 0 : _a.displayForm) && this.displayForm && this.username.trim().length > 0 && this.password.trim().length > 0) {
                    this.submitForm();
                }
            }, this.getAutomaticSelectionDelay());
        }
        // Register any click on window to stop automatic selection
        window.onclick = () => {
            this.stopAutomaticSelection();
        };
        // Define authenticationScheme styles
        if (this.config.authenticationSchemes && this.config.authenticationSchemes.length > 0) {
            const authenticationSchemesStyle = document.createElement('style');
            authenticationSchemesStyle.type = 'text/css';
            for (let a = 0; a < this.config.authenticationSchemes.length; a++) {
                let authenticationScheme = this.config.authenticationSchemes[a];
                let d = authenticationScheme.display;
                authenticationSchemesStyle.innerHTML += `
					.authenticationScheme${a} {
						border: 1px solid ${d['border-color']};
						color: ${d.color};
						background: ${d.background};
					}

					.authenticationScheme${a}:hover {
						color: ${d.overColor || d.color};
						background: ${d.overBackground || d.overBackground};
					}
				`;
                if (authenticationScheme === this.automaticSelectionAuthenticationScheme) {
                    authenticationSchemesStyle.innerHTML += `
						.${this.automaticSelectionCssClass} {
							animation: ${this.getAutomaticSelectionDelay()}ms ${this.automaticSelectionCssClass} linear forwards;
							background: linear-gradient(to left, ${d.background} 50%, ${d.overBackground} 50%);
							background-size: 200% 100%;
							background-position: bottom right;
						}

						@keyframes ${this.automaticSelectionCssClass} {
							0% {
								background-position: bottom right;
							}
							100% {
								background-position: bottom left;
								color: ${d.overColor};
							}
						}
					`;
                }
            }
            document.getElementsByTagName('head')[0].appendChild(authenticationSchemesStyle);
        }
    }
    showPassword() {
        this.set('isShowPassword', !this.isShowPassword);
    }
    showPassIcon(isShowPassword) {
        return !isShowPassword ? 'app:show-password' : 'app:hide-password';
    }
    inputType(isShowPassword) {
        return isShowPassword ? '' : 'password';
    }
    async userLogin() {
        var _a;
        let loginResult = await LoginUtils.userLogin((_a = this.config.ozoneApi) === null || _a === void 0 ? void 0 : _a.login, this.username, this.password);
        if (loginResult.status === 200) {
            let targetUrl = LoginUtils.getTargetUrl(this.config.targetParameter);
            LoginUtils.goToUrl(targetUrl);
        }
        else if (loginResult.status === 400) {
            this.set('errorMessage', this.localized.msgEmptyCredentials);
        }
        else if (loginResult.status === 403) {
            this.set('errorMessage', this.localized.msgInvalidCredentials);
        }
        else {
            this.set('errorMessage', this.localized.msgUnknownError);
        }
    }
    async submitForm() {
        if (this.validateForm()) {
            await this.userLogin();
        }
    }
    clearForm() {
        this.set('username', '');
        this.set('password', '');
    }
    validateForm() {
        const username = this.shadowRoot && this.shadowRoot.getElementById('username');
        username && username.validate();
        const password = this.shadowRoot && this.shadowRoot.getElementById('password');
        password && password.validate();
        if (!this.username.trim().length)
            username.focus();
        else if (!this.password.trim().length)
            password.focus();
        return this.username.trim().length > 0 && this.password.trim().length > 0;
    }
    async onKeyPress(e) {
        if (e.key === 'Enter') {
            await this.submitForm();
        }
    }
    getAuthenticationSchemeIdentifier(authenticationScheme) {
        let authenticationSchemeConfig = Object.assign({}, authenticationScheme);
        delete authenticationSchemeConfig.display;
        return '' + LoginUtils.hashCode(JSON.stringify(authenticationSchemeConfig));
    }
    getAuthenticationSchemeByIdentifier(identifier) {
        if (identifier && this.config.authenticationSchemes) {
            for (const authenticationScheme of this.config.authenticationSchemes) {
                if (identifier === this.getAuthenticationSchemeIdentifier(authenticationScheme)) {
                    return authenticationScheme;
                }
            }
        }
        return null;
    }
    getAutomaticSelectionDelay() {
        return this.config.selectionDelay || 3000;
    }
    stopAutomaticSelection() {
        var _a, _b;
        if (this.automaticSelectionTimer) {
            // Clear automaticSelectionTimer
            clearTimeout(this.automaticSelectionTimer);
            this.automaticSelectionTimer = null;
            // Remove automaticSelection style
            let elements = document.getElementsByClassName(this.automaticSelectionCssClass);
            for (let i = 0; i < elements.length; i++) {
                (_b = (_a = elements.item(i)) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.remove(this.automaticSelectionCssClass);
            }
            // Clear selected authenticationScheme
            LoginUtils.setCookieValue(this.config.selectionCookieName, '', this.config.defaultCookieOptions);
        }
    }
    selectAuthenticationScheme(authenticationScheme) {
        if (authenticationScheme) {
            // Save selected authenticationScheme
            if (this.config.selectionCookieName) {
                LoginUtils.setCookieValue(this.config.selectionCookieName, this.getAuthenticationSchemeIdentifier(authenticationScheme), this.config.defaultCookieOptions);
            }
            // Compute template variables
            const templateVariables = new Map([
                ['baseUrl', LoginUtils.getBaseUrl().toString()],
                ['targetUrl', LoginUtils.getTargetUrl().toString()],
                ['instance', this.ozoneInstance],
                ['virtualHost', this.ozoneVirtualHost],
                ['language', this.language],
            ]);
            if (authenticationScheme.type === 'form') {
                this._toggleForm();
            }
            else if (authenticationScheme.type === 'saml') {
                let redirectParameters = authenticationScheme.redirectParameters && new Map(Object.entries(authenticationScheme.redirectParameters));
                if (redirectParameters) {
                    redirectParameters.forEach((value, key) => {
                        redirectParameters.set(key, LoginUtils.processTemplate(value, templateVariables));
                    });
                }
                LoginUtils.samlLogin(authenticationScheme.idp, redirectParameters);
            }
            else if (authenticationScheme.type === 'redirect') {
                let redirectUrl = LoginUtils.processTemplateUrl(new URL(authenticationScheme.url, LoginUtils.getBaseUrl()), templateVariables);
                LoginUtils.goToUrl(redirectUrl);
            }
        }
    }
    _toggleForm() {
        var _a;
        if (!((_a = this.config.display) === null || _a === void 0 ? void 0 : _a.displayForm)) {
            if (this.displayForm && this.errorMessage) {
                this.clearForm();
            }
            this.set('errorMessage', '');
            this.set('displayForm', !this.displayForm);
            this.set('displayAuthenticationSchemes', !this.displayAuthenticationSchemes);
        }
    }
    countDisplayedAuthenticationSchemes() {
        let displayedAuthenticationSchemeCount = 0;
        if (this.config.authenticationSchemes) {
            for (const authenticationScheme of this.config.authenticationSchemes) {
                this._shouldDisplayAuthenticationScheme(authenticationScheme) && displayedAuthenticationSchemeCount++;
            }
        }
        return displayedAuthenticationSchemeCount;
    }
    _shouldDisplayBackBtn(displayForm, configDisplayForm) {
        return displayForm && !configDisplayForm;
    }
    _getFormStyle() {
        return this.config.display.displayForm ? "" : "height: " + Math.max(342, 119 + this.countDisplayedAuthenticationSchemes() * 56) + "px";
    }
    _getLocalizedValue(object) {
        return object && ((this.language && object[this.language]) || (this.config.defaultLanguage && object[this.config.defaultLanguage])) || undefined;
    }
    _shouldDisplayAuthenticationScheme(authenticationScheme) {
        var _a;
        return authenticationScheme.type !== 'form' || !((_a = this.config.display) === null || _a === void 0 ? void 0 : _a.displayForm);
    }
    _getAuthenticationSchemeClasses(authenticationScheme) {
        return (authenticationScheme === this.automaticSelectionAuthenticationScheme) ? this.automaticSelectionCssClass : '';
    }
    _selectAuthenticationScheme(e) {
        var _a, _b;
        const authenticationScheme = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.getElementById('drAuthenticationSchemes')) === null || _b === void 0 ? void 0 : _b.itemForElement(e.target);
        this.selectAuthenticationScheme(authenticationScheme);
    }
    _mouseOverAuthenticationScheme(e) {
        this.stopAutomaticSelection();
    }
};
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "config", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "username", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "password", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "focusedlogin", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "focusedpw", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "errorMessage", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PageLoginDefault.prototype, "ozoneInstance", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PageLoginDefault.prototype, "ozoneVirtualHost", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "language", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "localized", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "isShowPassword", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "displayForm", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "shouldDisplayOr", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "displayAuthenticationSchemes", void 0);
__decorate([
    property({ type: Boolean, computed: '_shouldDisplayBackBtn(displayForm, config.display.displayForm)' }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "shouldDisplayBackBtn", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "automaticSelectionAuthenticationScheme", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], PageLoginDefault.prototype, "automaticSelectionTimer", void 0);
PageLoginDefault = __decorate([
    customElement("page-login-default")
], PageLoginDefault);
export { PageLoginDefault };
