var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import "polymer/polymer-element.html";
import "app-route/app-route.html";
import "app-route/app-location.html";
import "iron-pages/iron-pages.html";
import '../pages/default/page-login/page.login.ts';
import '../pages/default/page-register/page.register.ts';
import '../pages/default/page-password/page.password.ts';
import '../pages/default/page-reset/page.reset.ts';
import '../pages/proximus/page-login/page.login.ts';
import '../pages/proximus/page-password/page.password.ts';
import '../pages/proximus/page-register/page.register.ts';
import '../pages/proximus/page-reset/page.reset.ts';
import * as _ from 'lodash';
import * as rrJson from 'really-relaxed-json';
import "./app-login.html";
import { customElement, observe, property } from "taktik-polymer-typescript";
import "../styles/index.scss";
import { LoginUtils } from "../app.utils";
let AppLogin = class AppLogin extends Polymer.Element {
    constructor() {
        super(...arguments);
        this.configLoaded = false;
        this.page = '';
        this.route = '';
        this.theme = '';
        this.ozoneInstance = '';
        this.ozoneVirtualHost = '';
        this.language = '';
    }
    ready() {
        super.ready();
        // load the configuration file
        fetch('login.json' + '?t=' + ~~(new Date().getTime() / 1000000))
            .then(response => {
            let appLogin = this;
            response.headers.forEach(function (value, name) {
                if (name.toLowerCase() === 'ozone-instance-id') {
                    appLogin.set('ozoneInstance', value);
                }
                else if (name.toLowerCase() === 'ozone-virtualhost-id') {
                    appLogin.set('ozoneVirtualHost', value);
                }
            });
            return response.text();
        })
            .then(configRaw => {
            const config = JSON.parse(rrJson.toJson(configRaw));
            const currentUrl = new URL(window.location.href);
            // Merge with query config
            let configParameterValue = currentUrl.searchParams.get(config.configParameter || "config");
            if (configParameterValue) {
                try {
                    let queryConfigJson = LoginUtils.base64UrlDecode(configParameterValue);
                    let queryConfig = JSON.parse(rrJson.toJson(queryConfigJson));
                    _.merge(config, queryConfig);
                }
                catch (e) {
                    console.log("Could not decode query configuration", e);
                }
            }
            // Set config
            this.set('config', config);
            // Select language
            const availableLanguages = this.config.languages ? Object.keys(this.config.languages) : [];
            const cookieLanguage = LoginUtils.getCookieValue(this.config.languageCookieName);
            let language = (cookieLanguage || (navigator.languages ? navigator.languages[0] : undefined) || navigator.language || navigator.userLanguage || '').slice(0, 2).toLowerCase();
            language = (availableLanguages.indexOf(language) >= 0) ? language : (availableLanguages.length > 0) ? availableLanguages[0] : (this.config.defaultLanguage || 'en');
            this.set('language', language);
            // Sync cookie language from current language if needed
            if (this.config.languageCookieName && cookieLanguage !== language) {
                LoginUtils.setCookieValue(this.config.languageCookieName, language, this.config.defaultCookieOptions);
            }
            // Set favicon
            if (this.config.favicon) {
                const link = document.getElementById('favicon');
                link.setAttribute('href', this.config.favicon);
            }
            // Set title
            const $title = document.querySelector('head>title');
            $title.innerHTML = this.config.headTitle || this.config.pageTitle || this.config.target.name;
        })
            .catch(err => {
            console.log(err);
        });
    }
    selectLanguage(language) {
        LoginUtils.setCookieValue(this.config.languageCookieName, language, this.config.defaultCookieOptions);
        window.location.reload();
    }
    hasTheme(name, theme) {
        return !!(name === theme);
    }
    _loadPage(theme, page) {
        switch (page + '-' + theme) {
            case 'login-default':
                import(/* webpackChunkName: "loginDefault" */ '../pages/default/page-login/page.login');
                break;
            case 'register-default':
                import(/* webpackChunkName: "registerDefault" */ '../pages/default/page-register/page.register');
                break;
            case 'password-default':
                import(/* webpackChunkName: "passwordDefault" */ '../pages/default/page-password/page.password');
                break;
            case 'reset-default':
                import(/* webpackChunkName: "resetDefault" */ '../pages/default/page-reset/page.reset');
                break;
            case 'login-proximus':
                import(/* webpackChunkName: "loginProximus" */ '../pages/proximus/page-login/page.login');
                break;
            case 'password-proximus':
                import(/* webpackChunkName: "passwordProximus" */ '../pages/proximus/page-password/page.password');
                break;
            case 'register-proximus':
                import(/* webpackChunkName: "registerProximus" */ '../pages/proximus/page-register/page.register');
                break;
            case 'reset-proximus':
                import(/* webpackChunkName: "resetProximus" */ '../pages/proximus/page-reset/page.reset');
                break;
        }
    }
    getLocalizedConfig(config, page, language) {
        const conf = {
            msgEmptyCredentials: "Missing credentials",
            msgInvalidCredentials: "Invalid credentials",
            msgUnknownError: "Unknown error. Please check your connection."
        };
        _.merge(conf, _.pick(config, ['pageIcon', 'coverPic', 'pageTitle', 'welcomeText', 'brandLogo']));
        if (!config.translations)
            return conf;
        if (config.translations[config.defaultLanguage] && config.translations[config.defaultLanguage]['common']) {
            _.merge(conf, config.translations[config.defaultLanguage]['common']);
        }
        if (config.translations[language] && config.translations[language]['common']) {
            _.merge(conf, config.translations[language]['common']);
        }
        if (!page)
            return conf;
        if (config.translations[config.defaultLanguage] && config.translations[config.defaultLanguage][page]) {
            _.merge(conf, config.translations[config.defaultLanguage][page]);
        }
        if (config.translations[language] && config.translations[language][page]) {
            _.merge(conf, config.translations[language][page]);
        }
        return conf;
    }
    _onConfigLoaded() {
        this.set('theme', this.config.theme);
        this.dispatchEvent(new CustomEvent('config-loaded', { detail: { config: this.config } }));
        this.configLoaded = true;
    }
    _routePageChanged(page) {
        // If no page was found in the route data, page will be an empty string.
        // Default to 'login' in that case.
        this.page = page || 'login';
    }
    _pageChanged(page) {
        // lazy load requested page taking theme into account.
        if (this.configLoaded) {
            this._loadPage(this.theme, this.page);
        }
        else {
            this.addEventListener('config-loaded', e => {
                this._loadPage(this.theme, this.page);
            });
        }
    }
};
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], AppLogin.prototype, "configLoaded", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], AppLogin.prototype, "config", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], AppLogin.prototype, "page", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], AppLogin.prototype, "route", void 0);
__decorate([
    property({ type: String, notify: true }),
    __metadata("design:type", String)
], AppLogin.prototype, "theme", void 0);
__decorate([
    property(),
    __metadata("design:type", String)
], AppLogin.prototype, "ozoneInstance", void 0);
__decorate([
    property(),
    __metadata("design:type", String)
], AppLogin.prototype, "ozoneVirtualHost", void 0);
__decorate([
    property(),
    __metadata("design:type", String)
], AppLogin.prototype, "language", void 0);
__decorate([
    observe('config'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppLogin.prototype, "_onConfigLoaded", null);
__decorate([
    observe('routeData.page'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppLogin.prototype, "_routePageChanged", null);
__decorate([
    observe('page'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppLogin.prototype, "_pageChanged", null);
AppLogin = __decorate([
    customElement("app-login")
], AppLogin);
export { AppLogin };
