var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import "polymer/polymer-element.html";
import "iron-flex-layout/iron-flex-layout-classes.html";
import "./page-login.html";
import "../theme.html";
import "../../../widgets/cover-image/cover.image.ts";
import { customElement, observe, property } from "taktik-polymer-typescript";
import { PageLoginDefault } from "../../default/page-login/page.login";
import { LoginUtils } from "../../../app.utils";
let PageLoginProximus = class PageLoginProximus extends PageLoginDefault {
    constructor() {
        super(...arguments);
        this.route = '';
    }
    login(e) {
        e.preventDefault();
        this.userLogin().then();
    }
    employeeLogin() {
        LoginUtils.samlLogin();
    }
    _routechanged() {
        setTimeout(() => {
            this.$.username.focus();
        }, 500);
    }
};
__decorate([
    property(),
    __metadata("design:type", Object)
], PageLoginProximus.prototype, "route", void 0);
__decorate([
    observe('route'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PageLoginProximus.prototype, "_routechanged", null);
PageLoginProximus = __decorate([
    customElement("page-login-proximus")
], PageLoginProximus);
